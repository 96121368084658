section[data-section='contacto'] {
  h2, h3 {
    color: $verde;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  h3 {
    margin-bottom: 5px;
  }

  .mapa {
    margin-top: 10px;
    padding: 5px;
    background: white;
    box-shadow: 0 0 2px grey;
  }

  form {
    label {
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: 400;
      margin-bottom: 5px;
      margin-top: 5px;
      letter-spacing: 1px;
    }

    .form-control {
      border-radius: 0;
    }

    button {
      border-radius: 0;
      border: none;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 300;
      background: $verde;
      margin-top: 5px;
    }
  }

  .vias {
    @include mobile{
      padding-top: 20px;
    }
    a {
      color: #333 !important;
    }
  }
}