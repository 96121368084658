section[data-section='data-novedad'] {
  .titulo {
    padding: 15px 0;
    text-transform: uppercase;
    color: $verde;
    letter-spacing: 2px;
  }

  figure {
    padding-bottom: 10px;

    img {
      margin: 0 auto;
      display: block;
      max-height: 600px;
    }
  }

  .body {
    p,span {
      font-size: 1.8rem !important;
      line-height: 1.4;
    }

  }
}