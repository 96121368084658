html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  background-image: url("/img/logos/logo_back.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  a:hover {
    text-decoration: none !important;

  }

  .section-page {
    padding: 0 5.5%;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;

    @include mobile {
      height: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    > div, > section {
      //border: 1px solid red;
      width: 100%;
    }
  }

  .list-novedades {
    .slick-list {
      padding: 10px 0;
    }

    button.slick-arrow {
      &:before {
        color: rgba(grey, .7) !important;
      }
    }
  }

  figure, img {
    line-height: 0;
  }

  p, li, a {
    font-size: 1.4rem;
  }

  .subtitle {
    color: $verde;
    text-transform: uppercase;
    font-weight: 300;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }

  h1, h2, h3, h4 {
    margin: 0;
    font-weight: 300;
  }
}


//@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
//
//  /* Force table to not be like tables anymore */
//  table, thead, tbody, th, td, tr {
//    display: block;
//  }
//
//  /* Hide table headers (but not display: none;, for accessibility) */
//  thead tr {
//    position: absolute;
//    top: -9999px;
//    left: -9999px;
//  }
//
//  tr {
//    border: 1px solid #ccc;
//  }
//
//  td {
//    /* Behave  like a "row" */
//    border: none;
//    border-bottom: 1px solid #eee;
//    position: relative;
//    padding-left: 50%;
//  }
//
//  td:before {
//    /* Now like a table header */
//    position: absolute;
//    /* Top/left values mimic padding */
//    top: 6px;
//    left: 6px;
//    width: 45%;
//    padding-right: 10px;
//    white-space: nowrap;
//  }
//
//
//}


@media screen and (max-width: 600px) {
  table {
    border: 0;

    td {
      text-align: left !important;
    }
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
    text-align: left;
  }

  table td:last-child {
    border-bottom: 0;
  }
}