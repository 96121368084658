section[data-section='institucional'] {
  //border: 1px solid red;
  display: flex;

  @include mobile {
    display: block;
  }

  p {
    text-align: justify;
    font-size: 1.5rem;
    line-height: 1.8;
  }

  h2 {
    text-transform: uppercase;
    color: $verde;
    margin-bottom: 10px;
    @include mobile{
      margin-top: 20px;
    }
  }

  .slider-inst-container {
    position: relative;
    width: 100%;

    .next, .prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(0, 0, 0, 0.6);
      border: none;
      z-index: 888;
      padding: 15px 10px;
      color: white;
      font-size: 1.3rem;

      i {
        line-height: 0;
      }
    }

    .prev {
      left: 0;
    }

    .next {
      right: 0;
    }

    .slider-inst {
      width: 100%;
    }
  }

}