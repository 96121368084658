#servicios {
  background-size: cover;
  background-position: center right;
}

section[data-section="servicios"] {

  .slider-servicios-container {
    position: relative;
    width: 100%;

    .next, .prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(0, 0, 0, 0.6);
      border: none;
      z-index: 888;
      padding: 15px 10px;
      color: white;
      font-size: 1.3rem;

      i {
        line-height: 0;
      }
    }

    .prev {
      left: 0;
    }

    .next {
      right: 0;
    }

    .slider-inst {
      width: 100%;
    }
  }

  h2 {
    @extend .subtitle;
    margin-bottom: 10px;
    @include mobile {
      margin-top: 20px;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 1.8;
    text-align: justify;
  }
}