.header-site {
  //border: 1px solid red;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  background: transparent;
  transition: all .3s ease;
  z-index: 999;
  padding: 7px 0 0 0;
  @include mobile {
    padding: 5px 0;
    height: 60px;
    background: white;
  }

  .open-menu {
    position: absolute;
    right: 4%;
    top: 50%;
    transform: translateY(-50%);
    color: $verde;
  }

  figure.logo {
    flex-basis: 25%;
    text-align: center;

    @include mobile {
      flex-basis: 100%;
    }

    img {
      max-height: 100%;
    }
  }

  &.active {
    padding: 5px 0;
    height: 60px;
    background: white;


    figure.logo {
      img {
        max-height: 100%;
      }
    }

    nav {
      a {
        color: $verde;
        text-shadow: none;
      }
    }
  }

  nav {
    flex-basis: 60%;
    display: flex;
    align-items: center;

    ul {
      flex-basis: 100%;
      padding: 0;
      display: flex;
      height: 40px;

      li {
        flex: 1;
        height: 100%;

        &.social {
          flex: 0.2;
          padding: 0 10px;

          &:last-child {
            margin-right: 10px;
          }
        }

        a {
          color: white;
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center;
          height: 100%;
          text-shadow: 0 0 3px black;
          letter-spacing: 1px;
          font-weight: 400;
          font-size: 1.3rem;
          line-height: 1.4;
          width: 100%;
          text-align: center;
          text-transform: uppercase;
          transition: all .3s ease;

          &:hover, &.active {
            text-decoration: none;
            background: $verde;
            color: white;
          }
        }
      }
    }
  }

  @include mobile {
    nav {
      position: fixed;
      background: white;
      left: 0;
      top: -100%;
      height: 100%;
      width: 100%;
      transition: all .3s ease;

      ul {
        flex-direction: column;
        height: unset;
        padding: 0 20px;

        li {
          height: auto;

          &.social {
            flex: unset;
            padding: unset;

            &:last-child {
              margin-right: unset;
            }
          }

          a {
            font-weight: 600;
            text-shadow: unset;
            color: $verde;
            padding: 10px 0;
            border-bottom: 1px solid $verde;
          }
        }
      }
    }
  }
}