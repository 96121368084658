section[data-section='novedades'] {
  h2 {
    text-transform: uppercase;
    color: $verde;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }

  .item-novedad {
    background: white;
    box-shadow: 0 0 1px 1px #dbdbdb;
    height: 470px;
    overflow: hidden;
    border-radius: 5px;
    color: black;
    transition: all .3s ease;
    @media (max-height: 800px) {
      height: 370px;
    }

    .image {
      height: 330px;
      background-size: cover;
      background-position: center center;

      @media (max-height: 800px) {
        height: 230px;
      }
    }

    img {
      max-height: 360px;
      margin: 0 auto;
      display: block;
    }

    &:hover {
      box-shadow: 0 0 2px 1px grey;
    }

    .data {
      padding: 15px;

      h3 {
        color: $verde;
        margin-bottom: 5px;
      }

      p {
        font-size: 1.2rem;
      }
    }

  }
}