footer {
  background: #2d2d2d;
  padding: 20px 3%;

  img {
    max-height: 100px;
    @include mobile {
      display: block;
      margin: 0 auto;
      padding-bottom: 10px;
      max-height: 70px;
    }
  }

  .data {
    li {
      color: white;
    }
  }
}