section[data-section='productos'] {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;

  .title-section {
    text-transform: uppercase;
    color: white;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 2px;
    display: block;
  }

  p {
    color: white;
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }

  .productos-tab {
    //background: white;
    height: 450px;
    //border:1px solid red;
    display: block;
    flex: 1;
    @include mobile {
      height: auto;
    }

    .tab-content-cat {
      transform: translateX(-30px);
      @include mobile {
        transform: unset;
      }

      .tab-pane {

        &.active {
          padding: 30px 50px;
          background: white;
        }
      }

      .nav-tabs {
        border: none;

        li {
          @include mobile{
            width: 100%;
            text-align: center;
          }

          &.active {
            background: $verde;
            border-radius: 2px;

            a {
              color: white;
            }
          }

          a {
            border: none;
            color: #333333;
            background: transparent;
            //text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }
      }

      .tab-content {
        padding: 20px 0 0 0;

        .tab-pane {
          padding: 0 10px;
        }

        h3 {
          margin-bottom: 5px;
          margin-top: 15px;
          display: block;
          color: $verde;
          text-transform: uppercase;
          font-size: 1.9rem;
          letter-spacing: 1px;
          font-weight: 500;
        }

        p {
          color: black;
          font-weight: 400;
          letter-spacing: 1px;
          margin-bottom: 0;
          font-size: 1.3rem;

          b {
            display: inline-block;
            padding-top: 3px;
          }
        }

        table {
          font-size: 1.4rem;

          th {
            font-weight: 500;
            text-transform: uppercase;
          }
        }
      }
    }

    ul.nav-categorias {
      li {
        cursor: pointer;

        a {
          color: white;
          font-weight: 400;
          letter-spacing: 1px;
          border: 1px solid white;
          background: transparent;
        }

        &.active {
          background: white;

          a {
            color: $verde;
          }
        }
      }
    }
  }
}